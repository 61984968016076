"use client";

import { useMemo } from "react";
import styles from "./Collection.module.scss";
import Link from "next/link";

interface Props {
  title: string;
  image: string;
  avatar: string;
  video?: string;
  uuid: string;
  url?: string;
}

export default function Collection(props: Props) {
  const { title, image, avatar, uuid, video = null, url = null } = props;

  const defineImage = useMemo(() => {
    return `${process.env.NEXT_PUBLIC_API_URL}/${image}` || "";
  }, [image]);

  const defineAvatar = useMemo(() => {
    return `${process.env.NEXT_PUBLIC_API_URL}/${avatar}` || "";
  }, [avatar]);

  const defineVideo = useMemo(() => {
    return `${process.env.NEXT_PUBLIC_API_URL}/${video}` || "";
  }, [video]);

  const defineUrl = useMemo(() => {
    return url ? url : `/colecao/a/${uuid}`;
  }, [uuid, url]);

  return (
    <Link prefetch={false} href={defineUrl} className={styles.collection}>
      {video ? (
        <video
          // autoPlay
          muted
          loop
          playsInline
          className={styles.video}
          poster={defineImage}
          preload="none"
          onMouseEnter={(e) => e.currentTarget.play()}
          onMouseLeave={(e) => e.currentTarget.pause()}
        >
          <source src={defineVideo} type="video/mp4" />
        </video>
      ) : (
        <img src={defineImage} alt={title} />
      )}
      <div className={styles.title}>
        {avatar && (
          <div className={styles.avatar}>
            <img src={defineAvatar} alt={title} width={100} height={100} />
          </div>
        )}
        <h1>{title}</h1>
      </div>
    </Link>
  );
}
