"use client";

import Destaque from "../Destaque";
import styles from "./DestaqueSlider.module.scss";

import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { useState } from "react";

interface Props {
  slides: any[];
}

export default function DestaqueSlider(props: Props) {
  const { slides } = props;

  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slides: {
      perView: 1,
      spacing: 0,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  return (
    <div
      style={{
        width: "calc(100vw - 1rem)",
        maxWidth: 1600,
        height: "100%",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        ref={sliderRef}
        className="keen-slider"
        style={{
          visibility: loaded ? "visible" : "hidden",
        }}
      >
        {slides.map((slide, idx) => (
          <div className="keen-slider__slide">
            <Destaque
              url={slide.button_url}
              height="400"
              title={slide.title}
              label={slide.button_label}
              background={slide.image?.url}
            />
          </div>
        ))}
      </div>
      <div className={styles.dots}>
        {[
          ...Array(instanceRef.current?.track?.details?.slides.length).keys(),
        ].map((idx) => {
          return (
            <div
              key={idx}
              onClick={() => {
                instanceRef.current?.moveToIdx(idx);
              }}
              className={currentSlide === idx ? styles.active : ""}
            />
          );
        })}
      </div>
    </div>
  );
}
